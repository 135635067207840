//
// Override global variables
//

// Theme colors
$primary:       									#313E71;
$primary-hover:    									#313E71;
$primary-light:    									#C9F7F5;
$primary-inverse:  									#FFFFFF;

$info:       									    #E0E9F1;
$info-light:    									#E0E9F1;
$info-hover:    									#E0E9F1;
$info-inverse:  									#FFFFFF;

$success:       									#05C9BD;
$success-hover:    									#05C9BD;
$success-light:    									#E1F0FF;
$success-inverse:  									#FFFFFF;