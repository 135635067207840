//
// Breadcrumb
//


.breadcrumb {
    align-items: center;

    .breadcrumb-item {
        a {
            display: list-item;
            align-items: center;
            margin-left: 0.5rem;

          &:last-child {
            &:after {
              content: "\2022";
              color: #000000;
              font-weight: bold;
              display: inline-block; 
              width: 1rem;
              margin-left: 2rem;
              margin-right: 1rem;
            }
          }
        }

        &.active,
        &:hover {
            i,
            a {
                transition: $transition-link;
                color: $primary !important;
            }
        }
    }

    &.breadcrumb-transparent {
        background-color: transparent;
        border-radius: 0;
    }

    &.breadcrumb-dot {
        .breadcrumb-item {
            display: flex;
            align-items: center;

            &:before {
                display: none;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    .new-breadcrumb-item {
        a {
            display: list-item;
            align-items: center;
            margin-left: 0.5rem;
            color: #707575;

            &:last-child {
                &:after {
                    content: url('/media/svg/icons/arrow-right.svg');
                    color: #000000;
                    font-weight: bold;
                    display: inline-block;
                    width: 1rem;
                    margin-left: 1rem;
                    margin-right: 0.5rem;
                }
            }
            
            &.active {
                transition: $transition-link;
                color: $gray-800 !important;
                &:last-child {
                    &:after {
                        content: '';
                    }
                }
            }
        }

        &.active,
        &:hover {

            i,
            a {
                transition: $transition-link;
                color: $gray-800 !important;
            }
        }
    }
}
