//
// Main Styles
//

.pagination {
  justify-content: center;
}

.rs-input-group-inside {
  height: 50px;
  border: 1px solid #F3F6F9 !important;
  border-radius: 1rem !important;
  .rs-date-range-input {
    border-radius: 1rem;
  }
}

#modal-delete-account {
  .modal-content {
    margin: 0 15%;
    border-radius: 24px;

    .modal-body {
      padding: 2.5rem 1.75rem;
    }
  }
}

#modal-preview-story {
  max-width: 100%;
  margin: 0;
  
  .modal-content {
    margin: 0;
    border-radius: 0;
    padding: 1%;
    background-color: #363636;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    .modal-header {
      border-radius: unset;
      border: none;
      justify-content: end;
      padding-right: 40px;
      padding-top: 30px;

      .close {
        font-size: 25px;

        span {
          display: block;
        }
      }
    }

    .modal-body {
      align-self: center;
      align-content: center;
    }
  }
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li a span[aria-hidden="true"] {
  font-size: 20px;
  margin-bottom: 0.5rem;
}

ul.pagination li.page-item.disabled .page-link span[aria-hidden="true"] {
  font-size: 20px;
  margin-bottom: 0.5rem;
}

ul.pagination li a {
  text-decoration: none;
  border: none;
}

ul.pagination li.page-item .page-link {
  width: 32px;
  height: 32px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.pagination li.page-item.disabled .page-link {
  border: none;
}

ul.pagination li.page-item.active .page-link {
  background-color: #EC7F6D !important;
  border-color: transparent;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  margin: 0;
  text-align: center;
}

ul.pagination li.page-item .page-link:hover {
  border-radius: 8px;
}

ul.pagination li.page-item:last-child .page-link,
ul.pagination li.page-item:first-child .page-link,
ul.pagination li.page-item:nth-child(2) .page-link,
ul.pagination li.page-item:nth-last-child(2) .page-link
{
  background-color: #F2F9FF !important;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
}

.dzu-dropzone {
  border: 1px solid #F3F6F9 !important;
  border-radius: 12px !important;
  overflow: hidden !important;
}

.btn.btn-primary.dropdown-toggle:after{
  color:#313E71 !important;
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 10px;
}

.apexcharts-tooltip {
  box-shadow:  none !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: 0 !important;
  background: transparent !important;
}
.apexcharts-xaxistooltip {
  opacity: 0 !important;
}

.loop-chip {
  .bg-chip {
    border-radius: 20px !important;
    &-no-border {
      border-radius: 8px !important;
    }
    &-info {
      background-color: #e0e9f1 !important;
    }
    &-default {
      background-color: #B0E8E5 !important;
    }
    &-danger {
      background-color: #F6B9AF !important;
    }
    &-secondary {
      background-color: #dcdee4 !important;
    }
    &-warn {
      background-color: #feeee2 !important;
    }
    &-reject {
      background-color: #b0b0b0 !important;
    }
  }
}

.has-search .form-control {
  padding-left: 3rem !important;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 3rem;
  height: 2.375rem;
  line-height: 4.3rem;
  text-align: center;
  pointer-events: none;
}

.tab-menu {
  text-transform: capitalize !important;
  font-size: 12px !important;
  &.Mui-selected {
    font-weight: 700 !important;
  }
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 18px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}


.image-container {
  position: relative;
  width: 100%;
  max-width: 26rem;
  height: auto;
  text-align: center;
  cursor: pointer;
  img {
    max-width: 100%;
    height: auto;
    z-index: 1;

    &:hover:after {
      background-color: #313E72;
      opacity: 0.5;
    }
  }

  .btn-section {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    .btn {
      background-color: transparent;
      color: transparent;
      font-size: 12px;
      padding: 12px 24px;
      border: none;
      cursor: pointer;
      border-radius: 12px;
      text-align: center;
    }
  
    .btn-primary {
      background-color: transparent;
      color: transparent;
      font-size: 12px;
      padding: 12px 24px;
      border: none;
      cursor: pointer;
      border-radius: 12px;
      text-align: center;
    }
  
    &:hover .btn {
      opacity: 1;
      background-color: #313E72;
      color: #FFFFFF;
    }
  
    &:hover .btn-primary {
      opacity: 1;
      background-color: #FEEEE2;
      color: #363636;
    }

  }

  &:before {
    content: '';
    opacity: 0;
    transition: opacity 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(49, 62, 114, 0.5);
    border-radius: 7px;
  }
  &:hover:before {
    opacity: 1;
  }
}
.bg-dpp-label {
  background-color: #EC7F6D;
}

.sun-editor {
  border: none !important;
  .se-toolbar {
    background-color: #FFFFFF !important;
    outline: none !important;
  }
  .se-resizing-bar {
    border-top: none !important;
    background-color: #FFFFFF !important;
  }
  .sun-editor-editable {
    padding-left: 0 !important;
  }
  .se-btn-tray {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}

.MuiTableRow-root {
  vertical-align: super !important;
}

.editor-wrapper {
  border: 1px solid #E9ECEF;
  border-radius: 6px;
}
.editor-toolbar {
  border: 0 !important;
  background: #F6F6F6 !important;
}

.editor-toolbar .rdw-option-wrapper {
  background: transparent;
}
.editor-toolbar .rdw-dropdown-wrapper {
  background: transparent;
}

.editor-box {
  min-height: 150px;
  padding: 15px;
}
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap/Material framework includes
@import "~bootstrap/scss/bootstrap";
@import "vendors/material-ui/general";

// 3: Components
@import
"components/utilities/background",
"components/utilities/flex",
"components/utilities/opacity",
"components/utilities/borders",
"components/utilities/sizing",
"components/utilities/position",
"components/utilities/text",
"components/utilities/icon",
"components/burger-icon",
"components/card",
"components/wizard",
"components/type",
"components/breadcrumb",
"components/nav",
"components/alert",
"components/buttons",
"components/code",
"components/dropdown",
"components/forms",
"components/grid",
"components/modal",
"components/progress",
"components/table",
"components/popover",
"components/tooltip",
"components/accordion",
"components/datatable",
"components/bar-chart",
"components/checkbox",
"components/radio",
"components/switch",
"components/option",
"components/dialog",
"components/strikethrough",
"components/separator",
"components/image-input",
"components/label",
"components/loader",
"components/symbol",
"components/pulse",
"components/page-loader",
"components/quick-search",
"components/scrolltop",
"components/spinner",
"components/sticky-toolbar",
"components/svg-icon",
"components/svg",
"components/timeline",
"components/timeline-2",
"components/timeline-3",
"components/timeline-4",
"components/timeline-5",
"components/overlay",
"components/demo",
"components/wave",
"components/list",
"components/bullet",
"components/navi",
"components/ribbon",
"components/toggle";

// 4: Plugins
@import
    "vendors/plugins/dropzone",
    "vendors/plugins/perfect-scrollbar",
    "vendors/plugins/prismjs",
    "vendors/plugins/react-bootstrap-table2";

// 5: Layout
@import "layout/init";
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////
