// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import '@metronic/_assets/sass/style.react.scss';

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.sidebar_menu {
  text-align: left;
  width: 268px !important;
}

.sidebar_menu p {
  margin: 0;
  padding: 10px;
}

.header_dropdown,
.dropdown_opened {
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
  margin: 85px 30px;
  border: 1px solid #E4E6EF;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.75);
  width: 180px;
  height: auto;
  transition: 0.2s;
  z-index: 9999;
}

.notification_dropdown,
.dropdown_notification_opened {
  position: absolute;
  right: 130px;
  top: 0;
  background-color: white;
  margin: 85px 30px;
  border: 1px solid #E4E6EF;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(179, 179, 179, 0.75);
  width: 320px;
  max-height: 380px;
  overflow: auto;
  transition: 0.2s;
  z-index: 9999;
}

.header_dropdown {
  transform: translateY(-30px);
  opacity: 0;
  visibility: hidden;
}

.dropdown_opened {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.notification_dropdown {
  transform: translateY(-30px);
  opacity: 0;
  visibility: hidden;
}

.dropdown_notification_opened {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.dropdown_open {
  transition: 0.5s;
  transform: rotateX(180deg);
}

.dropdown_close {
  transition: 0.5s;
  transform: rotateX(360deg);
}

.header_dropdown_content {
  display: flex;
  flex-direction: row;
  align-items: flex-center;
  margin: 15px 18px;
}

.header_dropdown_notification_content {
  margin: 15px 25px;
}

.admin_dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 8px 25px 0;
  transition: 0.5s;

  @media (max-width: 767px) {
    margin: 15px 8px 15px 0;
  }
}

.admin_dropdown:hover {
  cursor: pointer;
}

.header_dropdown_menu_text {
  font-weight: bold;
  font-size: 1.25rem;
}

.header_dropdown_notification_text {
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.header_dropdown_notification_unread {
  min-width: 8px;
  min-height: 8px;
  background-color: #F85C42;
  position: absolute;
  left: 10px;
  border-radius: 100%;
}

.header_dropdown_notification_subtitle {
  color: '#363636';
  font-size: 12px;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}
